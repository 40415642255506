import { IonProgressBar } from '@ionic/react'
import { getGridColor } from '../../utils/signalStrength'
import { ProgressBarStyles, SignalMeterStrengthStyles } from './SignalMeter.styles'

interface SignalTier {
  name: string
  breadth: number
  lowerBoundary: number
}

const getSignalTiers = () => {
  return [
    { name: 'Text', breadth: 0.333 },
    { name: 'Phone Calls', breadth: 0.333 },
    { name: 'High resolution video', breadth: 0.333 }
  ]
}

const getAccumulatedTiers = (): SignalTier[] => {
  const tiers = getSignalTiers()
  let lowerBoundary = 0
  tiers.forEach((tier) => {
    ;(tier as SignalTier).lowerBoundary = lowerBoundary
    lowerBoundary += tier.breadth
  })

  return tiers as SignalTier[]
}
const signalTiers = getAccumulatedTiers()

interface SignalMeterStrengthProps {
  normalizedQuality: number
  color?: string
}
export const SignalMeterStrength = (props: SignalMeterStrengthProps) => {
  const newColor = props.color ?? getGridColor(props.normalizedQuality)

  return (
    <>
      <IonProgressBar className={ProgressBarStyles(newColor)} color={newColor} value={props.normalizedQuality} />
      <div className={SignalMeterStrengthStyles}>
        {signalTiers.map((tier, idx) => {
          return (
            <div
              key={tier.name}
              style={{ width: `${tier.breadth * 100}%` }}
              className='signal-meter-tier ion-color-warning'
            >
              {idx > 0 ? <div className='tier-bar'></div> : null}
              <pre
                className={
                  'tier-text' +
                  (props.normalizedQuality && tier.lowerBoundary <= props.normalizedQuality ? ' dark-text' : '')
                }
              >
                {tier.name}
              </pre>
            </div>
          )
        })}
      </div>
    </>
  )
}
