import { css, cx } from '@emotion/css'
import { IonButton, IonText } from '@ionic/react'
import { UseCarrier } from '../services/Carrier.service'
import { NetworkType, NetworkTypeValue } from '../services/NetworkType.service'
import { PerformanceAverages } from '../services/planService/Plan.service'
import { getGridColor, getTitleForNetworkPerformance } from '../utils/signalStrength'

export function getNetworkTypeValue(networkType: NetworkTypeValue, averages: PerformanceAverages | undefined): number {
  if (!averages) {
    return 0
  }

  if (networkType === NetworkType['4G']) {
    return averages.average4GPerformance ?? 0
  }

  if (networkType === NetworkType['5G']) {
    return averages.average5GPerformance ?? 0
  }

  throw new Error('Invalid network type', { cause: { networkType } })
}

const SignalStrenghtErrorStyles = css`
  display: inline-flex;
  align-items: center;
  flex
`
const NetworkPerformanceQueryLabel = ({
  qualityQuery,
  networkType
}: {
  qualityQuery: ReturnType<(typeof UseCarrier)['useCarrierWithPerformance']>
  networkType: NetworkTypeValue
}) => {
  if (qualityQuery.isFetching || qualityQuery.isIdle) {
    return <>...</>
  }

  if (qualityQuery.isError) {
    return (
      <div className={SignalStrenghtErrorStyles}>
        <IonText color='danger'>Error while fetching.</IonText>
        <IonButton color='danger' onClick={() => qualityQuery.refetch()} size='small'>
          Try again
        </IonButton>
      </div>
    )
  }

  return (
    <NetworkPerformanceLabel
      networkPerformance={getNetworkTypeValue(networkType, qualityQuery.data?.networkPerformance)}
    />
  )
}

const NetworkPerformanceLabelStyles = (color: string) => css`
  border: 2px solid ${color};
  border-radius: 100px !important;
`
export const NetworkPerformanceLabel = ({ networkPerformance }: { networkPerformance: number }) => {
  const color = getGridColor(networkPerformance)

  return (
    <IonText class={cx(NetworkPerformanceLabelStyles(color), 'signal-strength-label')}>
      {getTitleForNetworkPerformance(networkPerformance)}
    </IonText>
  )
}

export default NetworkPerformanceQueryLabel
