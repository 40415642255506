import { NetworkType } from '../../services/NetworkType.service'

export function getNetworkTypeText(networkType: '4g' | '5g' | 'invalid'): keyof typeof NetworkType {
  return networkType !== 'invalid' ? (networkType.toUpperCase() as keyof typeof NetworkType) : '5G'
}

export function chatToNetworkType (networkType: '5g' | '4g' | 'invalid') {
  if (networkType === '5g') {
    return NetworkType['5G']
  }
  if (networkType === '4g') {
    return NetworkType['4G']
  }
  return NetworkType['5G']
}
