export const BinSize = {
  s9: 9,
  s8: 8,
  s7: 7,
  s6: 6,
  US: 5
} as const

export const usBoundary = 0
export const largeBoundary2 = BinSize.s6 + 2
export const largeBoundary = BinSize.s7 + 2
export const mediumBoundary = BinSize.s8 + 2
export const smallBoundary = BinSize.s9 + 3

export const loadPairs = {
  smallBin: { z: BinSize.s9, binSize: BinSize.s9 },
  mediumBin: { z: BinSize.s8, binSize: BinSize.s8 },
  largeBin: { z: BinSize.s7, binSize: BinSize.s7 },
  largeBin2: { z: BinSize.s6, binSize: BinSize.s6 },
  usBin: { z: BinSize.US, binSize: BinSize.US }
}
