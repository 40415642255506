import create from 'zustand'
import qs from 'query-string'
import { StateCreator } from 'zustand'
import { getInitialPreferencesV3, PlanPreferencesV3 } from './filter-plans'
import defaultHistory from '../../../router-history'
import { Logger } from '../../../utils/Logger'
import { MainNavigationPaths } from '../../../paths'

export type FiltersStoreState = PlanPreferencesV3

export type FiltersStoreActions = {}

type FiltersStoreType = FiltersStoreState & FiltersStoreActions

function shallowMerge<T extends Record<string, any>>(initial: T, extended: Partial<T>): T {
  if (typeof extended === 'string') {
    Logger.error('Invalid type for extended', extended)
    throw new Error('Invalid type for extended')
  }
  const final = { ...initial }
  Object.keys(extended).forEach((key) => {
    if (extended[key] !== undefined) {
      final[key as keyof T] = extended[key] as any
    }
  })

  return final
}

function qsAssertions(parsedQs: qs.ParsedQuery<string | number>): parsedQs is Partial<FiltersStoreType> {
  return typeof parsedQs.numLines === 'number' || parsedQs.numLines === undefined
}

const qsOptions: qs.ParseOptions = {
  arrayFormat: 'bracket',
  parseNumbers: true
}

function getParsedQs() {
  const parsedQs = qs.parse(defaultHistory.location.search.substring(1), qsOptions)

  if (!qsAssertions(parsedQs)) {
    Logger.error('Invalid query string parameters', parsedQs)
    throw new Error('Invalid query string parameters', { cause: parsedQs })
  }
  return parsedQs
}

function getFiltersFromUrl() {
  const selectedFilters: Partial<FiltersStoreType> = {}

  const initial = getInitialPreferencesV3()
  const parsedQs = getParsedQs()
  Object.keys(initial).forEach((_key) => {
    const key = _key as keyof FiltersStoreType
    if (Array.isArray(initial[key]) && parsedQs[key] && !Array.isArray(parsedQs[key])) {
      throw new Error(`Array should have been kept for key ${key}`, { cause: parsedQs })
    }
    selectedFilters[key as keyof FiltersStoreType] = (parsedQs[key as keyof FiltersStoreType] as any) ?? undefined
  })

  return shallowMerge(initial, selectedFilters)
}

let replaceUrl = true

const createNavigationSlice: StateCreator<FiltersStoreType, [], [], FiltersStoreType> = (set, get, api) => {
  const originalSetState = api.setState
  api.setState = (state) => {
    originalSetState(state)
    const searchParams = qs.stringify(
      {
        ...qs.parse(defaultHistory.location.search.substring(1), qsOptions),
        ...state
      },
      { arrayFormat: 'bracket' }
    )
    if (replaceUrl) {
      defaultHistory.replace({ ...defaultHistory.location, search: searchParams })
    }
  }

  return getFiltersFromUrl()
}

const useFiltersStore = create(createNavigationSlice)
listenToNavigation()

const pathsToListen = [MainNavigationPaths.plans]
function listenToNavigation() {
  return defaultHistory.listen((location) => {
    if (pathsToListen.every((path) => !location.pathname.startsWith(path))) {
      return
    }

    replaceUrl = false
    useFiltersStore.setState(getFiltersFromUrl())
    replaceUrl = true
  })
}

export default useFiltersStore
