import { handleRequest } from '../../api'
import { BackendNetworkTypes, ValueOf } from '../../services/NetworkType.service'
import { EventEmitter } from '../../utils/EventEmitter'
import type { BinaryFeatures } from '@loaders.gl/schema'
import type { Feature } from 'geojson'
import { BinSize } from '../../services/Bin.service'

export interface GeoJsonRecord {
  type: string
  geometry: {
    type: string
    coordinates: number[][][]
  }
  properties: {
    tileZ?: number
    carrier_id: string
    carrier_name: string
    network_performance: number
    network_type: string
    network_type_id: ValueOf<typeof BackendNetworkTypes>
    resolution: ValueOf<typeof BinSize>
  }
}

export interface MvtPayload {
  carrierId: string
  networkType: number
  zoomLevel: number
  tile_x: string
  tile_y: string
  tile_z: number
}

export interface GeoPayload {
  carrierId: string
  geojson: {
    type: string
    coordinates: number[][][]
  }
  zoomLevel: number
  networkType: number
}

export interface LoadOptions {
  payload: MvtPayload
  signal?: AbortSignal
}

export const GeoEvent = {
  changeLoadingState: 'changeLoadingState'
}

export type ParsedMvtTile = Feature[] | BinaryFeatures

export type GeoDataSource = {
  load: (options: LoadOptions) => Promise<Response>
}

export class M2DataSource implements GeoDataSource {
  async load(options: LoadOptions) {
    return handleRequest('/mvt/signals', {
      method: 'POST',
      body: JSON.stringify(options.payload),
      headers: {
        'Content-Type': 'application/json'
      },
      signal: options.signal
    })
  }
}

export class FCCDataSource implements GeoDataSource {
  async load(options: LoadOptions) {
    const payload = options.payload

    return handleRequest(
      `/mvt/fcc/signals/${payload.tile_z}/${payload.tile_x}/${payload.tile_y}?network_type_id=${payload.networkType}&carrier_id=${payload.carrierId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        signal: options.signal
      }
    )
  }
}

export class GeodataService extends EventEmitter {
  private loadingCount = 0

  private finalizeTask() {
    if (this.loadingCount > 0) {
      this.loadingCount--
    }
    if (this.loadingCount === 0) {
      this.emit(GeoEvent.changeLoadingState, false)
    }
  }

  constructor(public dataSource: GeoDataSource) {
    super()
  }

  async load(loadOptions: LoadOptions): Promise<Blob> {
    const promise = this.dataSource.load(loadOptions)

    if (this.loadingCount === 0) {
      this.emit(GeoEvent.changeLoadingState, true)
    }
    this.loadingCount++

    const ABORTED_MESSAGE = 'deck manager: request aborted'
    const data = await promise
      .then((response) => {
        this.finalizeTask()
        return response.blob()
      })
      .catch((e) => {
        if (e.message !== ABORTED_MESSAGE) {
          this.finalizeTask()
          throw e
        }
      })

    return data || new Blob()
  }
}
