import { parse } from '@loaders.gl/core'
import { binaryToGeojson } from '@loaders.gl/gis'
import { MVTWorkerLoader } from '@loaders.gl/mvt'
import { GoogleMapsOverlay, GoogleMapsOverlayProps } from '@deck.gl/google-maps/typed'
import { TileLayer } from '@deck.gl/geo-layers/typed'
import { getNetworkTypeId, NetworkTypeValue } from '../../services/NetworkType.service'
import { GeodataService, GeoJsonRecord, M2DataSource, MvtPayload } from './Geodata.service'
import { Logger } from '../../utils/Logger'
import { CustomTileIndex, generateTilesetForZoom, getCurrentZoomMapperItem, ZoomMapperItem } from './CustomTileset'
import { hexToRGB } from '../../utils/colors'
import { DeckDebugger, getTileGeojson, TileGeojsonRecord } from './DeckDebugger'
import { getGridColor, noSignalColor } from '../../utils/signalStrength'
import { TileLoadProps } from '@deck.gl/geo-layers/typed/tileset-2d'
import {
  largeBoundary,
  largeBoundary2,
  loadPairs,
  mediumBoundary,
  smallBoundary,
  usBoundary
} from '../../services/Bin.service'
import { GeoJsonLayer } from '@deck.gl/layers/typed'

export const SignalStrengths = [-100, -90, -80]
export const SignalQualities = [-20, -15, -10]

export async function processMvt(result: Blob, tile: TileLoadProps) {
  const parsed = await parse(result, MVTWorkerLoader, {
    mvt: {
      coordinates: 'wgs84',
      tileIndex: tile.index,
      shape: 'binary'
    }
  })

  if (DeckDebugger.enabled()) {
    const geojson = binaryToGeojson(parsed) as any[]
    geojson.push(getTileGeojson(tile))
    return geojson
  }

  return parsed
}

export class CustomMvtLayer extends TileLayer {
  private deckManager: DeckManager

  constructor(props: ConstructorParameters<typeof TileLayer>[0], deckManager: DeckManager) {
    super(props)
    this.deckManager = deckManager
  }

  async getTileData(tile: TileLoadProps) {
    if (!this.deckManager.carrierId) {
      return null
    }
    const reqBody: MvtPayload = {
      carrierId: this.deckManager.carrierId,
      zoomLevel: (tile.index as CustomTileIndex).binSize,
      networkType: this.deckManager.networkType,
      // TODO: this should be a backend fix. In the database x and y are inside a json, so it uses strings
      tile_x: String(tile.index.x),
      tile_y: String(tile.index.y),
      tile_z: tile.index.z
    }

    try {
      if (tile.signal?.aborted) {
        return null
      }

      const mvt = await this.deckManager.geodataService.load({
        signal: tile.signal,
        payload: reqBody
      })

      if (tile.signal?.aborted) {
        return null
      }

      return await processMvt(mvt, tile)
    } catch (error) {
      Logger.error(error)
      return null
    }
  }
}
CustomMvtLayer.layerName = 'CustomMvtLayer'

export type DeckManagerOptions = {
  map: google.maps.Map
  carrierId: string
  geodataService?: GeodataService
}
export class DeckManager {
  geodataService: GeodataService

  public map: google.maps.Map
  public carrierId: string

  constructor(options: DeckManagerOptions) {
    this.map = options.map
    this.carrierId = options.carrierId
    this.geodataService = options.geodataService ?? new GeodataService(new M2DataSource())
  }

  networkType: number = 4

  setNetworkType(networkType: NetworkTypeValue) {
    this.networkType = getNetworkTypeId(networkType)
  }

  setCarrierId(carrierId: string) {
    this.carrierId = carrierId
  }

  generateTileLayer({
    layerOptions,
    zoomMapper
  }: {
    layerOptions: ConstructorParameters<typeof TileLayer>[0] & ConstructorParameters<typeof GeoJsonLayer>[0]
    zoomMapper: ZoomMapperItem[]
  }) {
    const getFillColor = (item: GeoJsonRecord) => {
      if (!(item?.properties as any)?.network_performance) {
        return hexToRGB(noSignalColor)
      }

      const hex = getGridColor(item.properties.network_performance)
      const rgb = hexToRGB(hex)

      return [...rgb, 255]
    }

    const getOpacity = () => {
      const gmapZoom = this.map.getZoom()
      if (gmapZoom === undefined) {
        return 0
      }

      const deckZoom = gmapZoom - 1
      const zoomMapperItem = getCurrentZoomMapperItem(deckZoom, zoomMapper)
      if (!zoomMapperItem) {
        return 0
      }

      return zoomMapperItem.opacity === undefined ? 0 : zoomMapperItem.opacity
    }
    const opacity = getOpacity()

    const tileLayer = new CustomMvtLayer(
      {
        opacity,
        maxCacheSize: 10,
        parameters: {
          depthTest: false
        },
        maxZoom: 18,
        tileSize: 512,
        TilesetClass: generateTilesetForZoom(zoomMapper),
        getFillColor,
        getLineWidth: (item: GeoJsonRecord | TileGeojsonRecord) => {
          return DeckDebugger.enabled() ? DeckDebugger.debugLineWidth(item) : 1
        },
        getLineColor: (item: GeoJsonRecord | TileGeojsonRecord) => {
          return DeckDebugger.enabled() ? DeckDebugger.getLineColor(item) : [0, 0, 0, 255]
        },
        updateTriggers: {
          getTileData: { carrierId: this.carrierId, networkType: this.networkType }
        },
        ...layerOptions
      },
      this
    )

    return tileLayer
  }

  private overlay?: GoogleMapsOverlay = undefined

  private generateLayers(): GoogleMapsOverlayProps {
    const tileLayerUS = this.generateTileLayer({
      layerOptions: { id: 'layer_us', minZoom: usBoundary, maxZoom: largeBoundary2 },
      zoomMapper: [
        {
          minZoom: usBoundary,
          tileSizes: [loadPairs.usBin],
          opacity: 0.3333
        }
      ]
    })

    const tileLayerLarge2 = this.generateTileLayer({
      layerOptions: { id: 'layer_large2', minZoom: largeBoundary2, maxZoom: largeBoundary },
      zoomMapper: [
        {
          minZoom: largeBoundary2,
          tileSizes: [loadPairs.largeBin2],
          opacity: 0.3333
        }
      ]
    })

    const tileLayerLarge = this.generateTileLayer({
      layerOptions: { id: 'layer_large', minZoom: largeBoundary, maxZoom: mediumBoundary },
      zoomMapper: [
        {
          minZoom: largeBoundary,
          tileSizes: [loadPairs.largeBin],
          opacity: 0.3333
        }
      ]
    })

    const tileLayerMedium = this.generateTileLayer({
      layerOptions: { id: 'layer_medium', minZoom: mediumBoundary, maxZoom: smallBoundary },
      zoomMapper: [
        {
          minZoom: mediumBoundary,
          tileSizes: [loadPairs.mediumBin],
          opacity: 0.3333
        }
      ]
    })

    const tileLayerSmall = this.generateTileLayer({
      layerOptions: { id: 'layer_small', minZoom: smallBoundary },
      zoomMapper: [
        {
          minZoom: smallBoundary,
          tileSizes: [loadPairs.smallBin],
          opacity: 0.3333
        }
      ]
    })

    return { layers: [tileLayerUS, tileLayerLarge, tileLayerLarge2, tileLayerMedium, tileLayerSmall] }
  }

  applyLayers() {
    const layers = this.generateLayers()

    requestAnimationFrame(() => {
      this.overlay?.setProps(layers)
    })
  }

  centerAt(map: google.maps.Map, point: google.maps.LatLng) {
    const SELECT_ZOOM = 16

    map.setCenter(point)
    map.setZoom(SELECT_ZOOM)

    this.applyLayers()
  }

  async updateData() {
    const layers = this.generateLayers()

    // TODO: find better way to do this
    const forceLayerReinit = () => {
      this.overlay?.setProps({ layers: [] })
      window.requestAnimationFrame(() => {
        this.overlay?.setProps(layers)
      })
    }

    forceLayerReinit()
  }

  async setup() {
    const layers = this.generateLayers()
    this.overlay = new GoogleMapsOverlay(layers || {})
    if (process.env.NODE_ENV !== 'test') {
      this.overlay.setMap(this.map)
    }

    // Do we need to remove this listener?
    this.map.addListener('zoom_changed', () => {
      this.applyLayers()
    })
  }
}
