import { createGesture, Gesture } from '@ionic/react'
import React from 'react'

interface UseBottomTrayGestureProps {
  elementRef: React.RefObject<HTMLDivElement>
  enabled?: boolean
  onStart: () => void
  onMove: (ev: any) => void
  onEnd: (ev: any) => void
  direction?: 'x' | 'y'
}

const useSwipeGesture = ({ elementRef, onStart, onMove, onEnd, direction = 'y' }: UseBottomTrayGestureProps) => {
  const gestureRef = React.useRef<Gesture>()

  React.useEffect(() => {
    if (!elementRef.current) return

    gestureRef.current = createGesture({
      el: elementRef.current,
      gestureName: 'swipe',
      direction,
      onStart,
      onMove,
      onEnd
    })

    gestureRef.current.enable(true)
    const gesture = gestureRef.current

    return () => {
      gesture.destroy()
    }
  }, [direction, elementRef, onEnd, onMove, onStart])
}

export default useSwipeGesture
