import { css, cx } from '@emotion/css'
import { IonHeader, IonToolbar } from '@ionic/react'
import * as React from 'react'
import { MOBILE_BREAKPOINT } from '../../utils/hooks/useWindowSize'

const HeaderTitleStyles = css`
  margin-bottom: 0;
`
export function HeaderTitle(props: { children?: React.ReactNode[] | React.ReactNode; className?: string }) {
  return <h3 className={cx(HeaderTitleStyles, props.className)}>{props.children}</h3>
}

const BaseHeaderStyles = css`
  background-color: var(--goji-primary-lime);

  h3 {
    margin-top: 0;
  }

  &.remove-border {
    border-bottom: none;
  }

  &.add-border-radius {
    border-bottom: 2px solid var(--ion-color-dark);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    ion-toolbar {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }

  ion-toolbar {
    --background: var(--goji-primary-lime);
    position: relative;
  }

  color: var(--ion-color-dark);

  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    display: none !important;
  }
`

interface BaseHeaderToolbarProps {
  children?: React.ReactNode
  className?: string
  border?: boolean
  noBorder?: boolean
}
export function BaseHeaderToolbar(props: BaseHeaderToolbarProps) {
  return (
    <IonHeader
      className={cx(
        BaseHeaderStyles,
        props.border ? 'add-border-radius' : '',
        'ion-no-border',
        props.noBorder ? 'remove-border' : ''
      )}
    >
      <IonToolbar mode='ios' className={props.className}>
        {props.children}
      </IonToolbar>
    </IonHeader>
  )
}

export function BaseHeader({ children, className, ...props }: BaseHeaderToolbarProps) {
  return (
    <BaseHeaderToolbar {...props}>
      <div className={className}>{children}</div>
    </BaseHeaderToolbar>
  )
}
