import { SignalMeterStrength } from '../../SignalMeterStrength'
import { cx } from '@emotion/css'

const EXCELLENT_QUALITY = 8192

export const SpeedResult: React.FC<{ downloadSpeed: number; className?: string }> = ({ downloadSpeed, className }) => {
  const normalizedQuality = Math.min((downloadSpeed * 1024) / EXCELLENT_QUALITY, 1)

  return (
    <div className={cx('ion-padding-vertical', className)}>
      <div className='ion-padding-bottom'>
        Your current network performance should be able to handle the following activities:
      </div>
      <SignalMeterStrength normalizedQuality={normalizedQuality} />
    </div>
  )
}
