const nameToTitle = {
  poor: 'Poor',
  fair: 'Fair',
  good: 'Good',
  excellent: 'Excellent',
  noData: 'No data'
}

export const noSignalColor = '#B20009'

export const GridColorScale = ['#FF4F58', '#FD979C', '#F8F1B8', '#E5E832', '#5DBB61', '#265A28'] as const

export function getGridColor(performance: number) {
  return GridColorScale[Math.min(Math.max(Math.floor(performance * 10) - 1, 0), GridColorScale.length - 1)]
}

const MAX_NETWORK_PERFORMANCE = 10
export function normalizeNetworkPerformance(networkPerformance: number) {
  return networkPerformance / MAX_NETWORK_PERFORMANCE
}

export function getTitleForNetworkPerformance(performance: number) {
  if (performance === 0) {
    return nameToTitle.noData
  }
  if (performance < 0.4) {
    return nameToTitle.poor
  }

  if (performance < 0.7) {
    return nameToTitle.fair
  }

  if (performance < 0.8) {
    return nameToTitle.good
  }
  return nameToTitle.excellent
}
