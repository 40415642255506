import { WebMercatorViewport } from '@deck.gl/core/typed'
import { _Tileset2D as Tileset2D } from '@deck.gl/geo-layers/typed'
import { ZRange } from '@deck.gl/geo-layers/typed/tileset-2d'
import { TileIndex } from '@deck.gl/geo-layers/typed/tileset-2d/types'
import { Matrix4 } from '@math.gl/core'
import { bboxes, withinBbox } from './utils/bbox-calculations'

function getParentTileAt(tile: TileIndex, z: number): TileIndex {
  const x = Math.floor(tile.x / 2 ** (tile.z - z))
  const y = Math.floor(tile.y / 2 ** (tile.z - z))

  return { x, y, z }
}

function getChildTilesAt(tile: TileIndex, z: number): TileIndex[] {
  const childTiles: TileIndex[] = []
  const tilesPerAxis = 2 ** (z - tile.z)

  const xHigherZoom = tile.x * tilesPerAxis
  const yHigherZoom = tile.y * tilesPerAxis

  for (let i = 0; i < tilesPerAxis; i++) {
    for (let j = 0; j < tilesPerAxis; j++) {
      childTiles.push({
        x: xHigherZoom + i,
        y: yHigherZoom + j,
        z
      })
    }
  }

  return childTiles
}

export const zLarge = 9
export const zSmall = 13
function getTilesAtZoom(vIdx: TileIndex, z: number) {
  if (vIdx.z > z) {
    return [getParentTileAt(vIdx, z)]
  } else if (vIdx.z < z) {
    return getChildTilesAt(vIdx, z)
  }
  return [vIdx]
}

export interface ZoomMapperItem {
  minZoom: number
  tileSizes: { z: number; binSize: number }[]
  opacity?: number
}

export type CustomTileIndex = TileIndex & {
  binSize: number
}

export function getCurrentZoomMapperItem(zoom: number, zoomMapper: ZoomMapperItem[]) {
  const availableZooms = zoomMapper.filter(({ minZoom }) => Math.floor(zoom) >= minZoom)
  const largestZoom = availableZooms.reduce((acc: null | ZoomMapperItem, item) => {
    if (!acc) {
      return item
    }

    if (item.minZoom > acc.minZoom) {
      return item
    }
    return acc
  }, null)
  return largestZoom
}

export class CustomTileset extends Tileset2D {
  zoomMapper: ZoomMapperItem[] = []

  getTileIndices(opts: {
    viewport: WebMercatorViewport
    maxZoom?: number | undefined
    minZoom?: number | undefined
    zRange: ZRange | undefined
    tileSize?: number | undefined
    modelMatrix?: Matrix4 | undefined
    modelMatrixInverse?: Matrix4 | undefined
    zoomOffset?: number | undefined
  }): TileIndex[] {
    const zoom = Math.round(opts.viewport.zoom)

    const zoomMaxDiff = (opts.maxZoom ?? Infinity) - zoom
    const zoomMinDiff = zoom - (opts.minZoom || 0)

    if (zoomMaxDiff <= 0 || zoomMinDiff < 0) {
      return []
    }

    // User is now visualizing this tile.
    const viewportIndexes = super.getTileIndices(opts)

    const newIndexes = viewportIndexes.flatMap((vIdx) => {
      const zoomMapperItem = getCurrentZoomMapperItem(zoom, this.zoomMapper)

      const resultTiles = (zoomMapperItem?.tileSizes ?? []).flatMap((tileSize) => {
        const tiles = getTilesAtZoom(vIdx, tileSize.z)
        return tiles.filter((tile) => {
          ;(tile as CustomTileIndex).binSize = tileSize.binSize
          return bboxes.some((bbox) => withinBbox(tile, bbox))
        })
      })
      return resultTiles
    })

    return newIndexes
  }

  getParentIndex(_index: TileIndex): { x: number; y: number; z: number } {
    return { x: 0, y: 0, z: 0 }
  }
  getTileZoom(index: TileIndex): number {
    return index.z
  }
}

export function generateTilesetForZoom(zoomMapper: ZoomMapperItem[]) {
  return class ZoomTileSet extends CustomTileset {
    zoomMapper = zoomMapper
  }
}
