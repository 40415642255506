import React from 'react'
import useSwipeGesture from './useSwipeGesture'
import useMapOnboardingStore from './useMapOnboardingStore'
import { css } from '@emotion/css'

interface UseBottomTrayGestureProps {
  footerRef: React.RefObject<HTMLDivElement>
  addressDetailsRef: React.RefObject<HTMLDivElement>
  carrierListRef: React.RefObject<HTMLDivElement>
}

type Boundary = 'high' | 'medium' | 'low'

class BottomTrayManipulator {
  constructor(
    private options: UseBottomTrayGestureProps & {
      position: React.MutableRefObject<number>
      boundary: Boundary
      setBoundary: (boundary: Boundary) => void
    }
  ) {}

  addTransition() {
    if (!this.options.footerRef.current) {
      throw new Error('Footer ref is not available')
    }

    this.options.footerRef.current.style.transition = 'transform 0.5s ease-out'
  }

  removeTransition() {
    if (!this.options.footerRef.current) {
      throw new Error('Footer ref is not available')
    }
    this.options.footerRef.current.style.transition = 'none'
  }

  getPosition() {
    return this.options.position.current
  }

  setBoundary(boundary: Boundary) {
    this.options.setBoundary(boundary)

    this.setPosition(this.getBoundaryCheckpoint(boundary))
  }

  getBoundaryCheckpoint(boundary: Boundary = this.getBoundary()) {
    if (boundary === 'high') {
      return this.getHighBoundary()
    }
    if (boundary === 'medium') {
      return this.getMediumBoundary()
    }
    if (boundary === 'low') {
      return this.getLowBoundary()
    }
    throw new Error('Invalid boundary: ' + boundary)
  }

  getBoundary() {
    return this.options.boundary
  }

  setPosition(translateY: number) {
    if (!this.options.footerRef.current) {
      throw new Error('Footer ref is not available')
    }
    this.options.footerRef.current.style.transform = `translateY(${translateY}px)`
    this.options.position.current = translateY
  }

  getLowBoundary() {
    if (!this.options.footerRef.current) {
      throw new Error('Error while getting low boundary: Footer ref is not available')
    }
    const bottomTrayHeight = this.options.footerRef.current.querySelector('.bottom-tray')?.clientHeight ?? 0
    const trayHeadingHeight = this.options.footerRef.current.querySelector('.tray-heading')?.clientHeight ?? 0

    return bottomTrayHeight - trayHeadingHeight
  }

  getMediumBoundary() {
    return 0
  }

  getHighBoundary() {
    const carrierListHeight = this.options.carrierListRef.current?.clientHeight ?? 0
    return -carrierListHeight
  }
}

function useTrayEvents(options: { manipulator: BottomTrayManipulator }) {
  const { manipulator } = options

  const completeOnboard = useMapOnboardingStore((state) => state.completeOnboard)

  const onStart = React.useCallback(() => {
    manipulator.removeTransition()

    // getting the state directly is needed because if we use the hook, onStart changing creates a new gesture unnecessarily,
    // which makes the old gesture lose context of the div that was being dragged.
    if (!useMapOnboardingStore.getState().hasBeenOnboardedOnMapTray) completeOnboard()
  }, [manipulator, completeOnboard])

  const onMove = React.useCallback(
    (ev: any) => {
      const calculatedTranslateY = ev.deltaY + manipulator.getBoundaryCheckpoint()

      if (calculatedTranslateY < manipulator.getHighBoundary()) {
        return
      }

      manipulator.setPosition(calculatedTranslateY)
    },
    [manipulator]
  )

  const onEnd = React.useCallback(
    (ev: any) => {
      manipulator.addTransition()

      const updatedPositionY = manipulator.getPosition() + ev.deltaY

      const highBoundaryThreshold = (manipulator.getHighBoundary() + manipulator.getMediumBoundary()) / 2
      const lowBoundaryThreashold = (manipulator.getMediumBoundary() + manipulator.getLowBoundary()) / 2

      if (updatedPositionY <= highBoundaryThreshold) {
        manipulator.setBoundary('high')
      } else if (updatedPositionY >= lowBoundaryThreashold) {
        manipulator.setBoundary('low')
      } else {
        manipulator.setBoundary('medium')
      }
    },
    [manipulator]
  )

  return {
    onStart,
    onMove,
    onEnd
  }
}

export const boardingGestureStyles = (state: 'stale' | 'animate') => css`
  @keyframes moveFooter {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  animation: ${state === 'stale' ? 'none;' : 'moveFooter 2s infinite;'};
`

export const UseBottomTrayGesture = ({ footerRef, addressDetailsRef, carrierListRef }: UseBottomTrayGestureProps) => {
  const [boundary, setBoundary] = React.useState<Boundary>('medium')

  const position = React.useRef(0)

  const manipulator = React.useMemo(() => {
    return new BottomTrayManipulator({
      footerRef,
      addressDetailsRef,
      carrierListRef,
      position,
      boundary,
      setBoundary
    })
  }, [addressDetailsRef, carrierListRef, footerRef, boundary, position, setBoundary])

  const { onStart, onMove, onEnd } = useTrayEvents({ manipulator })

  useSwipeGesture({
    elementRef: addressDetailsRef,
    onStart,
    onMove,
    onEnd
  })

  const handleCollapse = React.useCallback(() => {
    if (boundary === 'low') {
      manipulator.setBoundary('medium')
    } else {
      manipulator.setBoundary('low')
    }
  }, [manipulator, boundary])

  return { handleCollapse, isCollapsed: boundary === 'low' }
}
