import * as React from 'react'
import { css, cx } from '@emotion/css'
import { IonCol, IonRow } from '@ionic/react'
import { Address } from '../../../store/address'
import { Plan } from '../../../services/planService/Plan'
import { UseCarrier, useSupportedCarriers } from '../../../services/Carrier.service'
import { GeoJsonRecord } from '../../../components/CoverageMap/Geodata.service'
import { NetworkType, NetworkTypeValue } from '../../../services/NetworkType.service'
import { getAverageNetworkPerformance } from '../../../services/Location.service'
import { Icon4g } from '../../../components/icons/Icon4g'
import { Icon5g } from '../../../components/icons/Icon5g'
import {
  NoCoverage,
  NoDataNetwork,
  UnsupportedNetwork,
  NetworkPerformanceState
} from '../../../components/PlanResult/NetworkPerformance'
import NetworkPerformanceDiagram from '../../../components/NetworkPerformanceDiagram/NetworkPerformanceDiagram'

const AddressCoverageCellStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  .coverage-row {
    display: flex;
    gap: 7px;
    align-items: center;
  }
  .network-type-icon {
    color: var(--ion-color-medium);
  }
`

export function AddressRow({
  address,
  plans,
  highlightEnabled
}: {
  address: Address
  plans: Plan[]
  highlightEnabled: boolean
}) {
  const carriers = UseCarrier.useBestCarriers({ addresses: [address] })
  const { isPlanCarrierSupported } = useSupportedCarriers()

  if (!carriers.data) {
    return null
  }

  function createPerformanceSet(carriers: GeoJsonRecord[], networkType: NetworkTypeValue) {
    return new Set(
      plans.map((plan) => {
        if (!isPlanCarrierSupported(plan)) {
          return -1
        }
        return Math.floor(getAverageNetworkPerformance(plan.carrierId, networkType, carriers))
      })
    )
  }

  const performanceSet5g = createPerformanceSet(carriers.data, NetworkType['5G'])
  const performanceSet4g = createPerformanceSet(carriers.data, NetworkType['4G'])

  const shouldHighlight5g = performanceSet5g.size > 1
  const shouldHighlight4g = performanceSet4g.size > 1

  return (
    <IonRow className='border-bottom' key={address.placeId}>
      <IonCol className='dark smaller-font'>{address.addressName}</IonCol>
      {plans.map((plan) => {
        return (
          <IonCol
            className={cx(
              AddressCoverageCellStyles,
              highlightEnabled && (shouldHighlight5g || shouldHighlight4g) ? 'highlight' : ''
            )}
            key={plan.planId}
          >
            <PerformanceCell address={address} plan={plan} />
          </IonCol>
        )
      })}
    </IonRow>
  )
}

function PerformanceCell({ address, plan }: { address: Address; plan: Plan }) {
  const carrierWithPerformance4g = UseCarrier.useCarrierWithPerformance({
    carrierId: plan.carrierId,
    addresses: [address],
    technology: NetworkType['4G']
  })

  const carrierWithPerformance5g = UseCarrier.useCarrierWithPerformance({
    carrierId: plan.carrierId,
    addresses: [address],
    technology: NetworkType['5G']
  })

  const { isPlanCarrierSupported } = useSupportedCarriers()
  const unsupported = !isPlanCarrierSupported(plan)

  if (unsupported) {
    return <AddressCoverage className='address-coverage' networkPerformance={0} unsupported />
  }

  return (
    <>
      <div className='coverage-row'>
        <AddressCoverage
          className='address-coverage'
          networkType={NetworkType['5G']}
          networkPerformance={carrierWithPerformance5g.data?.networkPerformance?.average5GPerformance ?? 0}
          unsupported={unsupported}
          noCoverage={plan.noCoverage}
        />
      </div>
      <div className='coverage-row'>
        <AddressCoverage
          className='address-coverage'
          networkPerformance={carrierWithPerformance4g.data?.networkPerformance?.average4GPerformance ?? 0}
          networkType={NetworkType['4G']}
          unsupported={unsupported}
          noCoverage={plan.noCoverage}
        />
      </div>
    </>
  )
}

const AddressCoverage = ({
  className,
  networkType,
  noCoverage,
  unsupported,
  networkPerformance
}: {
  className?: string
  networkType?: NetworkTypeValue
  networkPerformance: number
  noCoverage?: boolean
  unsupported?: boolean
}) => {
  return (
    <>
      {networkType === NetworkType['4G'] && (
        <span className='network-type-icon'>
          <Icon4g />
        </span>
      )}
      {networkType === NetworkType['5G'] && (
        <span className='network-type-icon'>
          <Icon5g />
        </span>
      )}
      <IonRow className={'coverage ' + className}>
        <PerformanceSwitch noCoverage={noCoverage} unsupported={unsupported} networkPerformance={networkPerformance} />
      </IonRow>
    </>
  )
}

function PerformanceSwitch(props: { networkPerformance: number; noCoverage?: boolean; unsupported?: boolean }) {
  const [networkPerformanceState, setNetworkPerformanceState] = React.useState<NetworkPerformanceState>('stale')

  if (props.unsupported) {
    return (
      <UnsupportedNetwork
        networkPerformanceState={networkPerformanceState}
        setNetworkPerformanceState={setNetworkPerformanceState}
      />
    )
  }

  if (props.noCoverage) {
    return (
      <NoCoverage
        networkPerformanceState={networkPerformanceState}
        setNetworkPerformanceState={setNetworkPerformanceState}
      />
    )
  }

  return props.networkPerformance === 0 ? (
    <NoDataNetwork
      networkPerformanceState={networkPerformanceState}
      setNetworkPerformanceState={setNetworkPerformanceState}
      networkType='4G/5G'
    />
  ) : (
    <NetworkPerformanceDiagram networkType='4G/5G' performance={props.networkPerformance} />
  )
}
