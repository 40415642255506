function isValueDifferent<T>(a: T, b: T) {
  if (Array.isArray(a) && Array.isArray(b)) {
    return a.length !== b.length || a.some((item) => !b.includes(item))
  }
  return a !== b
}

export function getFilterDiff<T>(storePreferences: T, newPreferences: T) {
  const diff: Partial<Record<keyof T, boolean>> = {}
  for (const _key in newPreferences) {
    const key = _key as keyof T

    if (isValueDifferent(storePreferences[key], newPreferences[key])) {
      diff[key] = true
    }
  }
  return diff
}
