import { css } from '@emotion/css'
import { IonRow, IonCol, useIonRouter } from '@ionic/react'
import React from 'react'
import NetworkPerformanceDiagram from '../NetworkPerformanceDiagram/NetworkPerformanceDiagram'
import { useBoundStore } from '../../store'
import { RoutePaths } from '../../paths'
import InfoDialog from '../InfoDialog'

const checkCoverageSvg = '/assets/icons/check-coverage.svg'
const noCoverageSvg = '/assets/icons/no-coverage.svg'
const alertSvg = '/assets/icons/alert.svg'

const NetworkStyles = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 4px;
  color: var(--goji-primary-black);

  span {
    text-decoration: underline;
    text-wrap: nowrap;
  }

  img {
    width: 16px !important;
  }
`

const CheckCoverageStyles = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--goji-primary-black);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  padding: 4px 16px;
  gap: 4px;
  margin: 4px 0;

  span {
    text-wrap: nowrap;
  }

  img {
    width: 16px !important;
  }
`

export function UnsupportedNetwork(props: {
  networkPerformanceState: NetworkPerformanceState
  setNetworkPerformanceState: (state: NetworkPerformanceState) => void
}) {
  return (
    <div
      className={NetworkStyles}
      onClick={(e) => {
        e.stopPropagation()
        props.setNetworkPerformanceState('unsupported-alert')
      }}
    >
      <img src={alertSvg} alt='Unsupported carrier' />
      <span>Unsupported</span>
      <InfoDialog
        isOpen={props.networkPerformanceState === 'unsupported-alert'}
        onClose={() => {
          props.setNetworkPerformanceState('stale')
        }}
        title='Unsupported carrier'
        content={`Pardon the dust, Goji currently doesn't support this carrier.\n\nBut if you happen to be on this carrier, try running a speed test using our handy Network Meter.`}
      />
    </div>
  )
}

export function NoCoverage(props: {
  networkPerformanceState: NetworkPerformanceState
  setNetworkPerformanceState: (state: NetworkPerformanceState) => void
}) {
  return (
    <div
      className={NetworkStyles}
      onClick={(e) => {
        e.stopPropagation()
        props.setNetworkPerformanceState('no-coverage-alert')
      }}
    >
      <img src={noCoverageSvg} alt='No coverage' />
      <span>No coverage</span>
      <InfoDialog
        isOpen={props.networkPerformanceState === 'no-coverage-alert'}
        onClose={() => {
          props.setNetworkPerformanceState('stale')
        }}
        title='No coverage for this location'
        content={`Looks like you've discovered a "dead zone" or no coverage from this carrier for this location.`}
      />
    </div>
  )
}

export function NoDataNetwork(props: {
  networkPerformanceState: NetworkPerformanceState
  setNetworkPerformanceState: (state: NetworkPerformanceState) => void
  networkType: '4G' | '5G' | '4G/5G'
}) {
  return (
    <div
      className={NetworkStyles}
      onClick={(e) => {
        e.stopPropagation()
        props.setNetworkPerformanceState(`no-data-alert-${props.networkType}`)
      }}
    >
      <img src={alertSvg} alt='No data' />
      <span>No data</span>
      <InfoDialog
        isOpen={props.networkPerformanceState === `no-data-alert-${props.networkType}`}
        onClose={() => {
          props.setNetworkPerformanceState('stale')
        }}
        title='No data for this location'
        content={`We don't have ${props.networkType} data for this location, but there may still be a signal for this carrier.\n\nIf you're at the location now, try running a speed test to see if there's coverage.`}
      />
    </div>
  )
}

export const UncontrolledNoDataNetwork = ({ networkType }: { networkType: '4G' | '5G' | '4G/5G' }) => {
  const [networkPerformanceState, setNetworkPerformanceState] = React.useState<NetworkPerformanceState>('stale')

  return (
    <NoDataNetwork
      networkPerformanceState={networkPerformanceState}
      setNetworkPerformanceState={setNetworkPerformanceState}
      networkType={networkType}
    />
  )
}

export function CheckCoverage() {
  const ionRouter = useIonRouter()
  return (
    <div
      className={CheckCoverageStyles}
      onClick={(e) => {
        e.stopPropagation()
        ionRouter.push(`${RoutePaths.addressBook}?back-to=${ionRouter.routeInfo.pathname}`)
      }}
    >
      <img src={checkCoverageSvg} alt='Check coverage' />
      <span>Check Coverage</span>
    </div>
  )
}

export type NetworkPerformanceState =
  | 'stale'
  | 'no-coverage-alert'
  | 'unsupported-alert'
  | 'no-data-alert-4G'
  | 'no-data-alert-5G'
  | 'no-data-alert-4G/5G'
type NetworkPerformanceProps = {
  networkPerformance4G?: number
  networkPerformance5G?: number
  noCoverage?: boolean
  unsupported?: boolean
}

const JustifyEndStyles = css`
  display: flex;
  justify-content: end;
`

export const NetworkPerformance = (props: NetworkPerformanceProps) => {
  const [networkPerformanceState, setNetworkPerformanceState] = React.useState<NetworkPerformanceState>('stale')

  const [addresses] = useBoundStore((state) => [state.addresses])
  const hasAddress = addresses.length > 0

  if (props.unsupported) {
    return (
      <IonRow className='ion-align-items-center network-performance' color='light'>
        <IonCol size='7'>Coverage:</IonCol>
        <IonCol size='5'>
          <div className={JustifyEndStyles}>
            <UnsupportedNetwork
              networkPerformanceState={networkPerformanceState}
              setNetworkPerformanceState={setNetworkPerformanceState}
            />
          </div>
        </IonCol>
      </IonRow>
    )
  }

  if (props.noCoverage) {
    return (
      <IonRow className='ion-align-items-center network-performance' color='light'>
        <IonCol size='8'>Coverage:</IonCol>
        <IonCol size='4'>
          <NoCoverage
            networkPerformanceState={networkPerformanceState}
            setNetworkPerformanceState={setNetworkPerformanceState}
          />
        </IonCol>
      </IonRow>
    )
  }

  return (
    <>
      {hasAddress ? (
        <>
          <IonRow className='ion-align-items-center network-performance' color='light'>
            <IonCol size='8'>4G Coverage:</IonCol>
            <IonCol size='4'>
              <div className={JustifyEndStyles}>
                {props.networkPerformance4G === undefined ? (
                  <NoDataNetwork
                    networkPerformanceState={networkPerformanceState}
                    setNetworkPerformanceState={setNetworkPerformanceState}
                    networkType='4G'
                  />
                ) : (
                  <NetworkPerformanceDiagram networkType='4G' performance={props.networkPerformance4G} />
                )}
              </div>
            </IonCol>
          </IonRow>
          <IonRow className='ion-align-items-center network-performance' color='light'>
            <IonCol size='8'>5G Coverage:</IonCol>
            <IonCol size='4' className='ion-text-right'>
              <div className={JustifyEndStyles}>
                {props.networkPerformance5G === undefined ? (
                  <NoDataNetwork
                    networkPerformanceState={networkPerformanceState}
                    setNetworkPerformanceState={setNetworkPerformanceState}
                    networkType='5G'
                  />
                ) : (
                  <NetworkPerformanceDiagram networkType='5G' performance={props.networkPerformance5G} />
                )}
              </div>
            </IonCol>
          </IonRow>
        </>
      ) : (
        <IonRow className='ion-align-items-center network-performance' color='light'>
          <IonCol>5G/4G Coverage:</IonCol>
          <IonCol>
            <div className={JustifyEndStyles}>
              <CheckCoverage />
            </div>
          </IonCol>
        </IonRow>
      )}
    </>
  )
}
