import { css, cx } from '@emotion/css'
import React from 'react'
import { UseCarrier } from '../../../services/Carrier.service'
import { NetworkType, NetworkTypeValue } from '../../../services/NetworkType.service'
import NetworkPerformanceDiagram from '../../NetworkPerformanceDiagram/NetworkPerformanceDiagram'
import { useBoundStore } from '../../../store'
import CarrierDisplay from '../../CarrierDisplay/CarrierDisplay'
import { Carrier } from '../../../store/carrierSelector'
import { PerformanceAverages } from '../../../services/planService/Plan.service'
import { IonIcon, IonSpinner } from '@ionic/react'
import { arrowDownOutline } from 'ionicons/icons'
import { UncontrolledNoDataNetwork } from '../../PlanResult/NetworkPerformance'
import { WarningBanner } from '../../ListBanner'

const carrierListStyles = css`
  background-color: var(--ion-background-color);
  border-left: 2px solid var(--ion-color-dark);
  border-right: 2px solid var(--ion-color-dark);
  max-height: 50vh;
  overflow-y: auto;
  transform: translateY(100%);
  left: -2px;
  width: calc(100% + 4px);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 16px 0;
  pointer-events: all;

  .carrier-list {
    width: 100%;
    table {
      width: 100%;
    }
    th,
    td {
      padding: 8px 0;
      text-align: right;
    }
    th:first-child,
    td:first-child {
      text-align: left;
      padding-left: 16px;
    }
    th:last-child,
    td:last-child {
      padding-right: 16px;
    }


    .selected {
      background-color: var(--goji-color-secondary-blue);
    }


    .sort-badge {
      width: fit-content;
      float: right;
      color: var(--ion-color-light);
      cursor: pointer;
      padding: 4px 12px;
      border-radius: 20px;
      border: 2px solid var(--ion-color-light);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      &:hover {
        background-color: var(--ion-color-light);
      }
      &.selected {
        color: #357e38;
        background-color: var(--ion-color-primary-tint);
        border: 2px solid transparent;
      }
      transition: background-color 0.3s, color 0.3s;

      > ion-icon {
        width: 0;
        background-color: transparent;
        transition: width 0.3s;
        &.selected {
          width: 16px;
        }
      }
  }
`

const loadingSpinnerStyles = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const carrierListWrapperStyles = css`
  position: absolute;
  bottom: 0;
  width: 100%;

  display: flex;
  justify-content: center;
  width: calc(100% + 4px);
  left: -2px;
  pointer-events: none;
  min-height: 50vh;
`

const emptyStateStyle = css`
  padding: 0px 24px;
  height: 100%;
  max-height: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;

  > * {
    max-width: 380px;
  }

  .empty-state-icon {
    margin-bottom: 2px;
    width: 24px;
    height: 24px;
  }

  .empty-state-title {
    font-size: 18px;
    font-weight: 700;
  }

  .empty-state-paragraph {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
`

const sortAndFilterCarriers = (
  carriers: { carrier: Carrier; networkPerformance?: PerformanceAverages }[],
  selectedTechnology: NetworkTypeValue
) => {
  return carriers
    .filter(
      (carrier) =>
        carrier.carrier.is_visible_on_map &&
        carrier.networkPerformance !== undefined &&
        (carrier.networkPerformance.average4GPerformance !== undefined ||
          carrier.networkPerformance.average5GPerformance !== undefined)
    )
    .sort((a, b) => {
      return Number(b.carrier.has_data) - Number(a.carrier.has_data)
    })
    .sort((a, b) => {
      if (selectedTechnology === NetworkType['5G']) {
        return (b.networkPerformance?.average5GPerformance ?? 0) - (a.networkPerformance?.average5GPerformance ?? 0)
      }
      return (b.networkPerformance?.average4GPerformance ?? 0) - (a.networkPerformance?.average4GPerformance ?? 0)
    })
}
interface CarrierCoverageListProps {
  contentRef: React.Ref<HTMLDivElement>
  selectedCarrierId?: string
}

const CarrierCoverageList: React.FC<CarrierCoverageListProps> = ({ contentRef, selectedCarrierId }) => {
  const [networkType, setNetworkType] = useBoundStore((state) => [state.networkType, state.setNetworkType])
  const [selectedAddress] = useBoundStore((state) => [state.selectedAddress])
  const carriers = UseCarrier.useAvailableInMyRegion(UseCarrier.useAllCarriers())

  const carriersWithPerformance = UseCarrier.useCarriersWithPerformance({
    carrierIds: carriers.data?.map((carrier) => carrier.id) ?? [],
    addresses: selectedAddress ? [selectedAddress] : undefined
  })

  const noCoverage = carriersWithPerformance.data?.every((carrier) => carrier.noCoverage)
  const noData = carriersWithPerformance.data?.every((carrier) => carrier.noData)
  const no5GData = carriersWithPerformance.data?.every((carrier) => !carrier.networkPerformance?.average5GPerformance)
  const no4GData = carriersWithPerformance.data?.every((carrier) => !carrier.networkPerformance?.average4GPerformance)
  const sortedCarriers = sortAndFilterCarriers(carriersWithPerformance.data ?? [], networkType)

  return (
    <div className={carrierListWrapperStyles}>
      <div className={carrierListStyles} ref={contentRef}>
        {!carriersWithPerformance.isFetched && (
          <div className={loadingSpinnerStyles}>
            <IonSpinner name='crescent' />
          </div>
        )}
        {sortedCarriers?.length > 0 && (
          <div className='carrier-list'>
            {no5GData || no4GData ? (
              <WarningBanner
                title={`No ${no5GData ? '5G' : '4G'} data for this location`}
                content="We don't have data for this location, but you can try running a speed test to see if there’s coverage."
              />
            ) : null}
            <table>
              <thead>
                <tr>
                  <th>Carrier coverage</th>
                  <th>
                    <div
                      onClick={() => setNetworkType(NetworkType['5G'])}
                      className={cx('sort-badge', networkType === NetworkType['5G'] && 'selected')}
                    >
                      5G
                      <IonIcon
                        icon={arrowDownOutline}
                        className={networkType === NetworkType['5G'] ? 'selected' : ''}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      onClick={() => setNetworkType(NetworkType['4G'])}
                      className={cx('sort-badge', networkType === NetworkType['4G'] && 'selected')}
                    >
                      4G
                      <IonIcon
                        icon={arrowDownOutline}
                        className={networkType === NetworkType['4G'] ? 'selected' : ''}
                      />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedCarriers?.map((carrier) => (
                  <tr key={carrier.carrier.id} className={carrier.carrier.id === selectedCarrierId ? 'selected' : ''}>
                    <td>
                      <CarrierDisplay carrier={carrier.carrier} />
                    </td>
                    <td>
                      {carrier.networkPerformance?.average5GPerformance ? (
                        <NetworkPerformanceDiagram
                          networkType='5G'
                          performance={carrier.networkPerformance.average5GPerformance}
                        />
                      ) : (
                        <UncontrolledNoDataNetwork networkType='5G' />
                      )}
                    </td>
                    <td>
                      {carrier.networkPerformance?.average4GPerformance ? (
                        <NetworkPerformanceDiagram
                          networkType='4G'
                          performance={carrier.networkPerformance.average4GPerformance}
                        />
                      ) : (
                        <UncontrolledNoDataNetwork networkType='4G' />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {!carriersWithPerformance.isLoading && !!noCoverage ? (
          <div className={emptyStateStyle}>
            <IonIcon src='/assets/icons/no-coverage.svg' className='empty-state-icon' />
            <span className='empty-state-title'>No coverage for this location</span>
            <span className='empty-state-paragraph'>
              Looks like you've discovered a "dead zone" or no coverage from this carrier for this location.
            </span>
          </div>
        ) : null}
        {!carriersWithPerformance.isLoading && !noCoverage && !!noData ? (
          <div className={emptyStateStyle}>
            <IonIcon src='/assets/icons/alert.svg' className='empty-state-icon' />
            <span className='empty-state-title'>No data for this location</span>
            <span className='empty-state-paragraph'>
              We don't have data for this location. If you use the carrier you’ve selected, you can try running a speed
              test to see if there’s coverage.
            </span>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CarrierCoverageList
