import { css, cx } from '@emotion/css'
import { GridColorScale } from '../../utils/signalStrength'
import { ResetMargin } from '../../utils/styles/MarginUtils.styles'
import CloseButton from '../CloseButton/CloseButton'
import TModal from '../TModal/TModal'

const DiamondStyles = (color: string) => css`
  width: 26px;
  height: 50px;
  background-color: ${color};
  border-radius: 4px;

  border: 1px solid #0000001f;
`
const LegendColor: React.FC<{ color: string }> = (props) => {
  return <div className={DiamondStyles(props.color)}></div>
}

const LegendSectionStyles = css`
  font-size: 14px;
  display: flex;
  gap: 24px;

  .legend-column {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .descriptions-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 0;
  }
`

const LegendTable: React.FC = () => {
  return (
    <section className={LegendSectionStyles}>
      <div className='legend-column'>
        {Object.values(GridColorScale)
          .reverse()
          .map((color) => (
            <LegendColor color={color} key={color} />
          ))}
      </div>
      <div className='descriptions-column'>
        <div>
          <b>Awesome signal</b> with high reliability! Go ahead and stream those high-resolution videos.
        </div>
        <div>
          <b>Meh signal</b> with marginal reliability. You can probably do some low-resolution video chat and streaming.
        </div>
        <div>
          <b>Weak signal</b> with low reliability. You can do phone calls and some basic browsing, but forget about
          video.
        </div>
        <div>
          <b>Terrible signal</b> with poor reliability. You're likely only able to send text messages and emojis.
        </div>
      </div>
    </section>
  )
}

const LegendModalStyles = css`
  background-color: var(--ion-background-color);
  max-height: 90vh;
  overflow-y: auto;
  .legend-modal-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;
  }

  .bottom-description {
    font-size: 14px;
    color: var(--ion-color-medium);
  }

  th {
    line-height: 125%;
    font-weight: 600;
  }
  tbody {
    background-color: var(--ion-color-primary-contrast);
  }
`
const LegendModal: React.FC<{ onClose: () => void; isOpen: boolean }> = (props) => {
  return (
    <TModal isOpen={props.isOpen} className={LegendModalStyles}>
      <section className='legend-modal-heading'>
        <h1 className={cx(ResetMargin, 'title')}>Legend</h1>
        <CloseButton onClose={props.onClose} />
      </section>
      <LegendTable />
      <section className='ion-margin-top bottom-description'>
        The coverage map is created using coverage data from the FCC.
      </section>
    </TModal>
  )
}

export default LegendModal
