import { MainNavigationPaths, RoutePaths } from '../../../paths'
import { useAutoChatStorage } from '../../llm/hooks/useChatStorage'

export function useResumePage(): string {
  const { chatStorage } = useAutoChatStorage()

  if (!chatStorage.data) {
    return ''
  }

  if (chatStorage.data.chatStatus === 'ended') {
    return MainNavigationPaths.results
  }
  return RoutePaths.chatFinder
}
